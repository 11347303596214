<script>
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";

export default {
  components: {
    ButtonRedirect,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    href: {
      type: String,
      required: true,
    },

    buttonText: {
      type: String,
      default: "Назад",
    },

    size: {
      type: String,
      default: "m",
      validator: (value) => ["m", "l"].includes(value),
    },
  },
};
</script>

<template>
  <div class="main-title-back" :class="`main-title-back_size-${size}`">
    <button-redirect
      class="main-title-back__button-redirect"
      :text="buttonText"
      @click="$router.push({ name: href })"
    />

    <h1 class="main-title-back__title">
      {{ title }}
    </h1>
  </div>
</template>

<style scoped lang="scss">
.main-title-back {
  margin-bottom: 64px;

  &__button-redirect {
    margin-bottom: $space-s;
  }

  &__title {
    color: $dark-primary;
    @include title-3;
  }
}

.main-title-back_size-l {
  .main-title-back {
    &__button-redirect {
      margin-bottom: $space-m;
    }

    &__title {
      @include title-2;
    }
  }
}

@media (max-width: 900px) {
  .main-title-back {
    margin-bottom: 40px;
  }
}

@media (max-width: 576px) {
  .main-title-back {
    margin-bottom: 32px;
  }
}
</style>
